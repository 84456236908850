import Link from 'next/link';
import { convert } from 'html-to-text';
import React, { useState, useEffect } from 'react';


const sanitizeHtml = (html) => {
  return convert(html, {
    wordwrap: 130,
    selectors: [{ selector: "img", format: "skip" }],
  });
};

const handleScroll = () => {
  const pageGlossaryContentNav = document.querySelector('.page-glossary_content--nav');
  if (pageGlossaryContentNav) {
    const rect = pageGlossaryContentNav.getBoundingClientRect();
    setIsFixed(rect.top <= 0);
  }
};

const SideSection = () => {
  const [featuredTerms, setFeaturedTerms] = useState([]);

  useEffect(() => {
    fetch('/api/glossary?featured=true')
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        
        setFeaturedTerms(sortedData.slice(0, 4));
      })
      .catch(error => console.error('Error fetching data:', error));

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function replaceSpacesWithHyphens(text) {
    return text.replace(/\s+/g, '-');
  }


  return (
    <>
      <h6 className='text-white fs-4 fw-bold mt-xl-5 mt-lg-5 mt-md-3'>Featured Terms</h6>
      <div className="sideBg">
        {featuredTerms.map((term) => (
          <div key={term._id} className='itemsBorder'>
            <h6 className='textGradient fs-5 fw-bold mb-2'>{term.title}</h6>
            <p className='fs-6 fw-regular text-white truncate-lines'>{sanitizeHtml(term.description)}</p>
            <Link className='textGradient fs-6 fw-semibold text-decoration-none' href={`/glossary-details/${replaceSpacesWithHyphens(term.title)}`} >Read More</Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default SideSection;
