import React, { useState } from 'react';
import Link from 'next/link';
import { Navbar, Container, Nav, Form, Offcanvas, Button, Col, Row, NavDropdown } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { useAuth } from '@/context/AuthContext';

const NavBar = () => {
  const { isLoggedIn, removeToken } = useAuth();
  const [showNav, setShowNav] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const expand = React.useMemo(() => 'lg', []);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    if (showNav) {
      setShowNav(false);
    }
  };

  return (
    <div className=''> 
      <div className='container'> 
<Navbar expand="lg" className='pt-3'> 
  <Container>
    <Navbar.Brand href="/" className='text-white fw-bold display-4 fs-2'>appran</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mx-auto">
        <NavDropdown title="Services" className="dropdown text-white">
          <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/#buy-section" target='_blank'>
            App Installs
          </NavDropdown.Item>
          <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/#future-section" target='_blank'>
            App Store Optimization
          </NavDropdown.Item> 
          <NavDropdown title="Mobile User Acquisition" className="dropend dropdownItem">
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/apple-search-ads" target='_blank'>
              Apple Search Ads   
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/facebook-ads" target='_blank'>
              Facebook Mobile Ads
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/tiktok-ads" target='_blank'>
              Tiktok Mobile Ads
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/instagram-mobile-ads" target='_blank'>
              Instagram Mobile Ads
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/reddit-ads" target='_blank'>
              Reddit Mobile Ads
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/google-ads" target='_blank'>
              Google Mobile Ads
            </NavDropdown.Item>
            <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/skan-audit" target='_blank'>
              SKAN 4.0 Audit
            </NavDropdown.Item>  
          </NavDropdown>
          <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/app-reviews-&-ratings" target='_blank'> 
            App Reviews & Ratings
          </NavDropdown.Item>
          <NavDropdown.Item className="dropdown-item text-white" href="https://appran.com/ugc-video-ads" target='_blank'>
            UGC Video Ads
          </NavDropdown.Item>
        </NavDropdown>
        <Nav.Link className="nav-link text-white">Marketplace</Nav.Link>
        <NavDropdown title="Resources" className="dropdown text-white">
          <NavDropdown.Item className="dropdown-item text-white" target='_blank' href="https://blog.appran.com/">
            Blog
          </NavDropdown.Item>
          <NavDropdown.Item className="dropdown-item text-white" target='_blank' href="https://case-studies.appran.com/">
            Case Studies
          </NavDropdown.Item>
          <NavDropdown.Item className="dropdown-item text-white" target='_blank' href="https://solutions.appran.com/">
            Solutions
          </NavDropdown.Item>  
          <NavDropdown.Item className="dropdown-item text-white" target='_blank' href="/#testimonials-section">
            Testimonials
          </NavDropdown.Item> 
          <NavDropdown.Item className="dropdown-item text-white" href="/">
            Glossary
          </NavDropdown.Item> 
        </NavDropdown>
        <Nav.Link href="https://appran.com/contact" className="nav-link text-white" target='_blank'>Contact</Nav.Link>
        <Nav.Link href="https://appran.com/#faq-section" className="nav-link text-white" target='_blank'>FAQs</Nav.Link>
      </Nav>
        <Link
            href="https://dashboard.appran.com"
            className="btn btn-lg btnOne rounded-pill py-3 mx-2 px-xxl-5 text-white my-3 my-xl-0 fs-6 fw-semibold"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
          >
            GET STARTED
          </Link>

          <Link
            href="https://calendly.com/admin-appran/appran-intro-meeting" target='_blank'
            className="btn btn-lg rounded-pill py-3 px-xxl-5 text-white my-3 my-xl-0 fs-6 fw-semibold btn-danger"
            // data-bs-toggle="modal"
            // data-bs-target="#exampleModal"
          >
            Book a Meeting
          </Link>
    </Navbar.Collapse>
  </Container>
</Navbar>

        <Offcanvas
          show={showSearch}
          onHide={() => setShowSearch(false)}
          placement="top"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className='textGradient fs-2 fw-bold'>
              Search
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form className='bg-white p-1 rounded-pill mt-2'>
              <Row className="d-flex justify-content-center">
                <Col className="m-0">
                  <Form.Control
                    size="lg"
                    id="inlineFormInput"
                    placeholder="Search appran Glossary"
                    className='costForm fs-6 rounded-pill border-0'
                  />
                </Col>
                <Col className="m-0" xs="auto">
                  <Button
                    className='bgColors fs-6 fw-bold rounded-pill px-5 py-3 border-0'
                    variant="primary"
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
}

export default NavBar;
