import React from 'react'    
import FB from "../assets/icons/fb.svg";
import Insta from "../assets/icons/insta.svg";
import Twitter from "../assets/icons/twitter.svg";
import Telegram from "../assets/icons/telegram icon.svg";
import Ticktok from "../assets/icons/tiktok.svg";
import Youtube from "../assets/icons/youtube.svg";
import LinkedIn from "../assets/icons/linkedin.svg";
import Link from 'next/link';



const FooterSection = () => {
  return (
    <footer id="footer-section" className="container-lg py-5 text-white">
      <div className="row">
        <div className="col-md-5 col-12">
          <h1 className="m-0 text-white fw-bold display-6">appran</h1>
          <p className="mt-3">
          Unlock endless possibilities - showcase your<br/>Android and iOS apps to a worldwide audience,<br/>and witness your creativity flourish.
          </p>
        </div> 
        <div className="col-md-5 col-12">
            <>
            <ul className="nav disDesk">
              <li className="nav-item">
                <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/apple-search-ads">
                Apple Search Ads
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/facebook-ads">
                Facebook Mobile Ads
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/#testimonials-section">
                Tiktok Mobile Ads
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/app-reviews-&-ratings">
                App Reviews & Rating
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/ugc-video-ads">
                UGC Video Ads
                </a>
              </li>
            </ul>
           

            <ul className="nav disDesk">
                <li className="nav-item">
                  <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/instagram-mobile-ads">
                  Instagram Mobile Ads
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/reddit-ads">
                  Reddit Mobile Ads
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/google-ads">
                  Google Mobile Ads
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/skan-audit">
                  SKAN 4.0 Audit
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link text-white text-opacity-50 fw-semibold" target="_blank" href="https://appran.com/contact">
                  Contact
                  </a>
                </li> 
              </ul>
              </>
        </div>
        <div className="col-md-2 col-12">
        <div className="socialLinks">
          <a target="_blank" href={"https://www.tiktok.com/@user4593057596996"}>
            <img src="/icons/tiktok.svg" height={34} />
          </a>
          <a target="_blank" href={"https://www.linkedin.com/company/appran/?_l=en_US"}>
            <img src="/icons/linkedin.svg" height={34} />
          </a>
          <a target="_blank" href={"https://t.me/Apprantalks"}>
            <img src="/icons/telegram icon.svg" height={34} />
          </a>
          <a target="_blank" href={"https://www.facebook.com/Apprantalks"}>
            <img src="/icons/fb.svg" height={34} />
          </a>
          <a target="_blank" href={"https://twitter.com/AppRanTalks"}>
            <img src="/icons/twitter.svg" height={34} />
          </a>
          <a target="_blank" href={"https://www.instagram.com/apprantalks/"}>
            <img src="/icons/insta.svg" height={34} />
          </a>
          <a target="_blank" href={"https://www.youtube.com/@apprantalks"}>
            <img src="/icons/youtube.svg" height={34} />
          </a>
        </div>
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between flex-wrap text-white text-opacity-75">
        <p className="m-0">
          Copyright © · All Rights Reserved · <u>AppRan</u>
        </p>
        <p className="m-0">
          <a target="_blank" href="https://appran.com/terms-conditions" className="link-light text-opacity-75">
            Terms & Conditions
          </a>
          <span className="px-1">|</span>
          <a  target="_blank" href="https://appran.com/privacy-policy" className="link-light text-opacity-75">
            Privacy Policy
          </a>
        </p>
      </div>
    </footer>
  )
}

export default FooterSection
