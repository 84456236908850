import React, { useState } from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        toast.success('Form data sent successfully');
        setFormData({
          fullName: '',
          email: '',
          subject: '',
          message: ''
        });
      } else {
        toast.error('Failed to send form data');
      }
    } catch (error) {
      toast.error('Error sending form data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contactBg" id='contact-form'>
      <h4 className="textGradient fs-2 fw-bold text-center mb-3">
        Get Free App Promotion Now - Limited Time Offer
      </h4>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12}>
            <Form.Control
              size="lg"
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Full Name"
              className="costField mb-3"
              required
            />
          </Col>
          <Col xs={12}>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="costField mb-3"
              required
            />
          </Col>
          <Col xs={12} className="mb-xs-3">
            <Form.Control
              size="lg"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Subject"
              className="costField"
              required
            />
          </Col>

          <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows={5}
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Message"
              className="costField"
              required
            />
          </Form.Group>

          <div className="d-grid gap-2 mt-2">
            <button type="submit" disabled={loading} className="btnStyles py-3 rounded-0">
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default ContactForm;
